// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContrastText": "#fff",
	"errorDark": "#d32f2f",
	"errorLight": "#e57373",
	"errorMain": "#f44336",
	"primaryContrastText": "#fff",
	"primaryDark": "#172271",
	"primaryLight": "#cccfe2",
	"primaryMain": "#26328c",
	"secondaryContrastText": "#fff",
	"secondaryDark": "#00776a",
	"secondaryLight": "#20a294",
	"secondaryMain": "#009688",
	"textDisabled": "rgba(0,0,0,.38)",
	"textHint": "rgba(0,0,0,.38)",
	"textPrimary": "rgba(0,0,0,.7)",
	"textSecondary": "rgba(0,0,0,.54)",
	"fontSizeBody1": "1rem",
	"fontSizeBody2": ".875rem",
	"fontSizeH1": "1.7rem",
	"fontSizeH2": "1.5rem",
	"fontSizeH3": "1.4rem",
	"fontSizeH4": "1.25rem",
	"fontSizeH5": "1.1rem",
	"fontSizeH6": ".875rem",
	"fontSizeSubtitle1": ".875rem"
};
export default ___CSS_LOADER_EXPORT___;
